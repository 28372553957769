
import styled from '@emotion/styled'
import React from 'react'
import { ThemeToggler } from 'gatsby-plugin-dark-mode'
import { css } from '@emotion/react'

const Circle = styled.div`
transform: scale(0.83);
cursor: pointer;
top: 0.625rem;
width: 1.5rem;
transition: 0.3s ease-out;
height: 1.5rem;
border-radius: 50%;
background-color: var(--color);

&:before {
    content: "";
    width: 1.5rem;
    transition: 0.3s ease-out;
    height: 1.5rem;
    border-radius: 50%;
    position: absolute;
    left: 0.5rem;
    bottom: 0.5rem;
    background-color: var(--background-color);
    transform: var(--translate)
    
}
&:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: -0.25rem;
    border-radius: 50%;
}

@media screen and (min-width: 479px) {
      width: 1.5rem;
      height: 1.5rem;
      transform: scale(1);

      &:before {
    width: 1.5rem;
    height: 1.5rem;
    left: 0.5rem;
    bottom: 0.5rem;
    
}
&:after {
    margin: -0.25rem;
}

    }
`

function DarkModeToggle() {

    return (
        <ThemeToggler>
            {({ theme, toggleTheme }) => (


                <Circle
                    onClick={() => {
                        console.log(theme)
                        theme === 'dark' ? toggleTheme('light') : toggleTheme('dark')
                    }}
                >
                </Circle>


            )}
        </ThemeToggler>

    )
}

export default DarkModeToggle
