import { css } from "@emotion/react";

const variables = css`
  :root {
    --font-sans: "Calibre", "Inter", "San Francisco", "SF Pro Text",
      -apple-system, system-ui, sans-serif;
    --font-mono: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", monospace;
    --easing: cubic-bezier(0.645, 0.045, 0.355, 1);
    --transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  :root body.light {
          --background-color: #fff;
          --text-secondary: #6e6e73;
          --color: #1d1d1f;
          --text-content-color: #3c4856;
          --contrast: #000;
          --translate: translate(0, 0);
          --background-image: #f8f8f8;
          --filter: invert(0);
          --stroke: rgb(29, 29, 31);
          
        }

        :root body.dark {
          --background-color: #100818;
          --text-secondary:   #87878c;
          --color: hsla(0, 0%, 100%, 0.9);
        
          --text-content-color: hsla(0, 0%, 100%, 0.65);
          --translate: translate(10px, -10px);
          --background-image: #17101e;
          --filter: invert(1);
        }

`;

export default variables;
