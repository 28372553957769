import React, { useEffect } from "react";
import styled from "@emotion/styled";
import GlobalStyles from "../Styles/GlobalStyles";
import Header from "./Header";
import Footer from "./Footer";
import { motion } from "framer-motion"

import { AnimatePresence } from 'framer-motion';
import { ThemeProvider } from "@emotion/react";
import theme from "../Styles/theme";

const Main = styled.main``;

const Layout = ({ children, location, data }) => {
  useEffect(() => {
    setTimeout(() => {
      document.querySelector("body").classList.remove("preload-transitions");
    }, 1000);
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Header path={location.pathname} />
        <Main>

          {children}
        </Main>
        <Footer />
      </ThemeProvider>
    </>
  );
};

export default Layout;
