import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { Link } from "gatsby";
import React, { useRef } from "react";

import ArrowLeft from "../../assets/svg/arrow-left.svg";
import GitHub from "../../assets/svg/github.svg";

import LinkedIn from "../../assets/svg/linked-in.svg";
import Mail from "../../assets/svg/mail.svg";
import usePrefersReducedMotion from "../../hooks/usePrefersReducedMotion";

const Root = styled.header`
  padding: 1.5rem 1rem;

  @media screen and (min-width: 479px) {
    padding: 2rem;
  }
  @media screen and (min-width: 768px) {
    padding: 2rem 2.5rem;
  }
  @media screen and (min-width: 991px) {
    padding: 3.75rem 5rem;
  }
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1;
`;

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;

  svg {
    vertical-align: middle;
    margin-left: 1rem;
    @media screen and (min-width: 479px) {
      margin-left: 0;
    }
  }

  a {
    @media screen and (min-width: 479px) {
      font-size: 1.25rem;
      margin-left: 1.5rem;
    }
    @media screen and (min-width: 767px) {
      margin-left: 2rem;
    }
  }
`;

const NavLink = styled(Link)`
  letter-spacing: 1px;
  line-height: 1em;

  @media screen and (min-width: 479px) {
    font-size: 1.25rem;
  }
`;

const Back = styled(Link)`
  display: flex;
  align-items: center;

  span {
    margin-left: 0.5rem;
    @media screen and (min-width: 479px) {
      margin-left: 0.75rem;
      font-size: 1.25rem;
    }
  }
`;

const SocialLinks = styled.div`
  display: none;
  line-height: 1;

  @media screen and (min-width: 479px) {
    display: initial;
  }
`;



const fadeIn = keyframes`
from {
    opacity: 0;
    transform: translate3d(0, -20%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const Header = ({ path }) => {

  return (
    // random key ensures animation is rerun whily ensuring layout is not rendered completely again
    <Root
      id="top"
      key={Math.random()}
      css={css`
        animation: ${fadeIn} 1s ease;
      `}
    >
      <Nav>

        {path === "/" ? (
          <>
            <NavLink to={"/"} aria-label="Home">
              Julian B.
            </NavLink>

            <HeaderRight>

              {/* <NavLink to={"/about"} aria-label="About">
                About
              </NavLink> */}

              <SocialLinks>
                <a href="https://github.com/jlnbxn" aria-label="LinkedIn">
                  <GitHub />
                </a>
                <a href="www.linkedin.com/in/jlnbxn" aria-label="LinkedIn">
                  <LinkedIn />
                </a>
                <a href="mailto:julian@boxan.net" aria-label="Email">
                  <Mail />
                </a>
              </SocialLinks>

            </HeaderRight>
          </>
        ) : (
          <Back to={"/"} aria-label="Home">
            <ArrowLeft />
            <span>Back</span>
          </Back>
        )}
      </Nav>
    </Root>
  );
};

export default Header;
