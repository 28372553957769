import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import ArrowUp from "../../assets/svg/arrow-up.svg";
import LinkedIn from "../../assets/svg/linked-in.svg";

import GitHub from "../../assets/svg/github.svg";
import Toggle from "../../assets/svg/toggle-left.svg";
import Mail from "../../assets/svg/mail.svg";
import { css } from "@emotion/react";
import DarkModeToggle from "./DarkModeToggle";
import { Link } from "gatsby";
import sr from "../../utils/sr";
import { srConfig } from "../../config";
import usePrefersReducedMotion from "../../hooks/usePrefersReducedMotion";

const Root = styled.footer`
  padding: 3.75rem 1rem 1.5rem;

  @media screen and (min-width: 479px) {
    padding: 2.5rem 2rem;
  }
  @media screen and (min-width: 768px) {
    padding: 3rem 2.5rem;
  }
  @media screen and (min-width: 991px) {
    padding: 1.5rem 5rem 3.75rem;
  }
  svg {
    width: 1.25rem;
    height: 1.25rem;
    @media screen and (min-width: 479px) {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`;

const DarkModeToggleWrapper = styled.div`
position: relative;
@media screen and (min-width: 479px) {
      font-size: 1.25rem;
      margin-left: 1.5rem;
      display: initial;
    }
    @media screen and (min-width: 767px) {
      margin-left: 2rem;
    }
`

const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: 1.5rem;
`;

const SocialLinks = styled.div`
  display: flex;
  align-items: center;
  line-height: 1;
  svg {
    vertical-align: middle;
    margin-left: 1rem;
    @media screen and (min-width: 479px) {
      margin-left: 0;
    }
  }
  a {
    @media screen and (min-width: 479px) {
      font-size: 1.25rem;
      margin-left: 1.5rem;
    }
    @media screen and (min-width: 767px) {
      margin-left: 2rem;
    }
  }
`;

const BackToTop = styled.a`
  display: flex;
  align-items: center;
  span {
    margin-left: 0.5rem;
    @media screen and (min-width: 479px) {
      margin-left: 0.75rem;
      font-size: 1.25rem;
    }
  }
`;

const hideOnMobile = css`
  display: none;
  @media screen and (min-width: 479px) {
    display: inline-block;
  }
`;

const Footer = () => {

  const revealFooter = useRef(null)
  const prefersReducedMotion = usePrefersReducedMotion()
  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }
    sr.reveal(revealFooter.current, srConfig(100))
  }, []);


  return (
    <Root >
      <Inner ref={revealFooter} >

        <BackToTop href="#top">
          <ArrowUp />
          <span>Back to Top</span>
        </BackToTop>

        <DarkModeToggleWrapper>
          <DarkModeToggle />
        </DarkModeToggleWrapper>
        {/* 
        <Toggle /> */}

        <SocialLinks>

          {/* <Link css={hideOnMobile} to="/about" aria-label="About">About</Link> */}

          <a href="https://github.com/jlnbxn" aria-label="LinkedIn">
            <GitHub />
          </a>
          <a href="www.linkedin.com/in/jlnbxn" aria-label="LinkedIn">
            <LinkedIn />
          </a>

          <a href="mailto:julian@boxan.net" aria-label="Email">
            <Mail />
          </a>
        </SocialLinks>

      </Inner>
    </Root>
  );
};

export default Footer;
