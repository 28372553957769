// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-projects-epubpress-epubpress-mdx": () => import("./../../../src/projects/epubpress/epubpress.mdx" /* webpackChunkName: "component---src-projects-epubpress-epubpress-mdx" */),
  "component---src-projects-markdownloader-markdownloader-mdx": () => import("./../../../src/projects/markdownloader/markdownloader.mdx" /* webpackChunkName: "component---src-projects-markdownloader-markdownloader-mdx" */),
  "component---src-projects-pickywiki-pickywiki-mdx": () => import("./../../../src/projects/pickywiki/pickywiki.mdx" /* webpackChunkName: "component---src-projects-pickywiki-pickywiki-mdx" */),
  "component---src-projects-posterior-posterior-mdx": () => import("./../../../src/projects/posterior/posterior.mdx" /* webpackChunkName: "component---src-projects-posterior-posterior-mdx" */),
  "component---src-projects-rankerr-rankerr-mdx": () => import("./../../../src/projects/rankerr/rankerr.mdx" /* webpackChunkName: "component---src-projects-rankerr-rankerr-mdx" */),
  "component---src-projects-strync-strync-mdx": () => import("./../../../src/projects/strync/strync.mdx" /* webpackChunkName: "component---src-projects-strync-strync-mdx" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */)
}

