export default {
    xs: `400px`,
    sm: `550px`,
    md: `768px`,
    lg: `990px`,
    xl: `1200px`,
    xxl: `1522px`
    // xxl: `95.125rem`
}

