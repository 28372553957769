const { css } = require("@emotion/react");

const mixins = {
    styledLink: css`
    border-bottom: 1px solid hsl(0deg 0% 53% / 40%);
    text-decoration: none;
    transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    &:hover {
      border-bottom-color: hsl(0deg 0% 53%);
    }
  `
};

export default mixins;