import breakpoints from "../../utils/breakpoints";
import React from "react";
import TransitionStyles from "./TransitionStyles";

import "@fontsource/lora/400.css";
import "@fontsource/lora/500.css";
import "@fontsource/dm-sans";

import { Global, css } from "@emotion/react";
import variables from "./variables";

// filter effect for photos on blog page from clay.global -webkit-filter: drop-shadow(0px 15px 34px rgba(0, 0, 0, 0.08));
// filter: drop-shadow(0px 15px 34px rgba(0, 0, 0, 0.08));

const GlobalStyles = () => {
  return (
    <Global
      styles={css`
        ${variables};

        html {
          box-sizing: border-box;
          font-size: 16px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          max-width: 100%;
          overflow-x: hidden;
        }

        body {
          font: 100%/1.55 DM Sans, -apple-system, BlinkMacSystemFont, Segoe UI,
            Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
          background-color: var(--background-color);
          color: var(--color);
          transition: background-color 0.3s ease-out;
          -webkit-text-size-adjust: 100%;
          box-sizing: border-box;
          overflow-y: scroll;
          margin: 0;
          word-wrap: break-word;
          font-kerning: normal;
          font-feature-settings: "kern" 1;
          text-rendering: optimizeSpeed;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          max-width: 100%;
          overflow-x: hidden;
          // letter-spacing: -0.004em;
          -webkit-text-size-adjust: 100%;
        }

        .preload-transitions {
          -webkit-transition: none !important;
          -moz-transition: none !important;
          -ms-transition: none !important;
          -o-transition: none !important;
          transition: none !important;
        }

        html.sr .reveal {
          visibility: hidden;
        }

        svg {
          stroke: var(--color);
        }

        .gatsby-image-wrapper {
          background-color: var(--background-image);
          transition: 0.3s ease-out;
        }
        .gatsby-resp-image-image {
          box-shadow: none !important;
        }

        *,
        *::before,
        *::after {
          box-sizing: inherit;
        }

        a {
          text-decoration: none;
          color: inherit;
          cursor: pointer;
          &:hover {
            text-decoration: none;
          }
        }

        img {
          width: 100%;
        }

        li {
          text-decoration: none;
          list-style: none;
          margin: 0;
          padding: 0;
        }

        ul {
          text-decoration: none;
          list-style: none;
          margin: 0;
          padding: 0;
        }

        p {
          margin: 0;
        }

        a {
          &:hover {
            text-decoration: none;
          }
          &:active {
            text-decoration: none;
          }
        }

        h1 {
          font-size: 1.75rem;

          @media only screen and (min-width: ${breakpoints.sm}) {
            font-size: 2.25rem;
          }
          @media only screen and (min-width: ${breakpoints.xl}) {
            font-size: 2.5rem;
          }
        }

        h2 {
          font-size: 1.5rem;
          font-weight: 300;
          margin: 0;

          @media only screen and (min-width: ${breakpoints.md}) {
            font-size: 1.625rem;
          }

          @media only screen and (min-width: ${breakpoints.xl}) {
            font-size: 2rem;
          }
        }

        figure {
          margin: 0;
        }

        ${TransitionStyles}
      `}
    />
  );
}

export default GlobalStyles;
